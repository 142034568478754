<div mat-dialog-title>Jump To ...</div>

<div mat-dialog-content class="content">
  @for (group of groups; track $index) {
  <div class="group">
    <h4>{{ group.name }}</h4>
    <div class="links">
      @for (item of group.children; track $index) {
      <a
        mat-raised-button
        [routerLink]="['/cars', item.name]"
        routerLinkActive
        #rla="routerLinkActive"
        [disabled]="rla.isActive"
        >{{ item.name }}</a
      >
      }
    </div>
  </div>
  }
</div>

<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Close</button>
</div>
