<mat-icon class="material-symbols-outlined">{{ icon }}</mat-icon>
<div class="content">
  <h4>{{ title }}</h4>
  <div
    @bar
    class="bar"
    [ngStyle]="{ width: width, 'background-color': color }"
  ></div>
</div>
<span class="value">
  {{ value | number : "0.1-1" }}
</span>
