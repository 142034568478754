const r = (r, a, t) => r * (1 - t) + a * t,
  a = r => `rgb(${r.r}, ${r.g}, ${r.b}${r.a ? ", " + r.a : ""})`,
  t = r => {
    const a = r.toString(16);
    return 1 == a.length ? "0" + a : a;
  },
  e = (r, a) => `#${t(r.r)}${t(r.g)}${t(r.b)}${a ? t(255 * a) : ""}`,
  n = r => "object" == typeof r && "r" in r && "number" == typeof r.r && "g" in r && "number" == typeof r.g && "b" in r && "number" == typeof r.b,
  o = r => "string" == typeof r && /^(rgb)?a?\(?\W?([01]?\d\d?|2[0-4]\d|25[0-5])(\W+)([01]?\d\d?|2[0-4]\d|25[0-5])\W+(([01]?\d\d?|2[0-4]\d|25[0-5])(\W+)?(0(\.\d+)?|1(\.0+)?)?\W?\)?)$/.test(r),
  s = r => "string" == typeof r && /^#([0-9a-fA-F]{2}){3,4}$/.test(r),
  g = r => {
    var a;
    if (!o(r)) throw new Error("Input values must be a valid rgb string value.");
    const t = (null !== (a = r.match(/\d{1,3}/g)) && void 0 !== a ? a : ["0", "0", "0"]).map((r, a) => {
        const t = parseInt(r);
        if (3 === a && (t < 0 || t > 1)) throw new Error(`Value out of range for an alpha value: ${r}.`);
        if ((t < 0 || t > 255) && a < 3) throw new Error(`Value out of range for an RGB value: ${r}.`);
        return t;
      }),
      e = t.length > 4 ? t[4] / Math.pow(10, t[4].toString().length) : 4 === t.length ? t[3] : void 0;
    return {
      r: t[0],
      b: t[1],
      g: t[2],
      a: e
    };
  },
  i = r => {
    var a;
    if (!s(r)) throw new Error("Input values must be a valid Color Hex value.");
    const t = (null !== (a = r.match(/[0-9a-fA-F]{2}/g)) && void 0 !== a ? a : ["0", "0", "0"]).map((r, a) => {
      const t = 3 === a ? parseInt(r, 16) / 255 : parseInt(r, 16);
      if (3 === a && (t < 0 || t > 1)) throw new Error(`Value out of range for an alpha value: ${r}.`);
      if (t < 0 || t > 255) throw new Error(`Value out of range for an RGB value: ${r}.`);
      return t;
    });
    return {
      r: t[0],
      g: t[1],
      b: t[2],
      a: 4 === t.length ? t[3] : void 0
    };
  },
  l = r => {
    if (!Array.isArray(r) || 3 !== r.length && 4 !== r.length) throw new Error("Input values must be an array of length 3 or 4.");
    const a = r.map((r, a) => {
      let t = r;
      if ("string" == typeof t) {
        if (t = 3 === a ? parseFloat(t) : parseInt(t), isNaN(t)) throw new Error(`Invalid value: ${r}.`);
      } else {
        if (3 === a && (t < 0 || t > 1)) throw new Error(`Value out of range for an alpha value: ${r}.`);
        if (t < 0 || t > 255) throw new Error(`Value out of range for an RGB value: ${r}.`);
      }
      return t;
    });
    return {
      r: a[0],
      g: a[1],
      b: a[2],
      a: 4 === a.length ? a[3] : void 0
    };
  },
  u = r => {
    let t = {
      r: 0,
      g: 0,
      b: 0
    };
    if (n(r)) {
      if (r.a && (r.a < 0 || r.a > 1)) throw new Error(`Value out of range for an alpha value: ${r}.`);
      t = {
        r: r.r,
        g: r.g,
        b: r.b,
        a: r.a
      };
    } else o(r) ? t = g(r) : s(r) ? t = i(r) : Array.isArray(r) && (t = l(r));
    return {
      rgb: t,
      toString: a(t),
      toHex: e(t, t.a)
    };
  },
  h = (r, a) => ({
    colors: r.map(r => u(r)),
    ranges: a.sort((r, a) => r - a)
  }),
  f = function (a, t, e = .5) {
    e > 1 && (e = 1), e < 0 && (e = 0);
    const n = r(a.rgb.r, t.rgb.r, e),
      o = r(a.rgb.g, t.rgb.g, e),
      s = r(a.rgb.b, t.rgb.b, e);
    return u({
      r: Math.round(n),
      g: Math.round(o),
      b: Math.round(s)
    });
  };
function p(r, a) {
  return {
    map: h(r, a),
    getColor(r, a) {
      if (r <= this.map.ranges[0]) return this.map.colors[0];
      if (r >= this.map.ranges[this.map.ranges.length - 1]) return this.map.colors[this.map.ranges.length - 1];
      let t = u(Object.assign(Object.assign({}, this.map.colors[0].rgb), {
        a: a
      }));
      return this.map.ranges.forEach((e, n) => {
        if (r > e && r < this.map.ranges[n + 1]) {
          const o = e,
            s = this.map.ranges[n + 1],
            g = (r - o) / (s - o);
          t = f(this.map.colors[n], this.map.colors[n + 1], g), t = a ? u(Object.assign(Object.assign({}, t.rgb), {
            a: a
          })) : t;
        } else r === e && (t = a ? u(Object.assign(Object.assign({}, this.map.colors[n].rgb), {
          a: a
        })) : this.map.colors[n]);
      }), t;
    }
  };
}
export { l as arrayToRGB, f as blendColorByRatio, p as colorRange, u as createColor, i as hexToRGB, s as isHex, n as isRGB, o as isRGBString, g as stringToRGB };
