import { Routes } from '@angular/router';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { AboutPageComponent } from './pages/about-page/about-page.component';
import { CarTabsPageComponent } from './pages/car-tabs-page/car-tabs-page.component';
import { CarTabPageComponent } from './pages/car-tab-page/car-tab-page.component';

export const routes: Routes = [
  {
    path: '',
    component: HomePageComponent,
    pathMatch: 'full',
  },
  {
    path: 'about',
    component: AboutPageComponent,
    pathMatch: 'full',
  },
  {
    path: 'cars',
    component: CarTabsPageComponent,
    children: [
      {
        path: ':name',
        pathMatch: 'full',
        component: CarTabPageComponent,
      },
    ],
  },
];
