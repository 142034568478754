import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'carImage',
  standalone: true,
})
export class CarImagePipe implements PipeTransform {
  transform(c: any, ...args: unknown[]): String {
    return `${c.id}.png`;
  }
}
