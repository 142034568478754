<mat-toolbar color="primary" class="mat-elevation-z4">
  <h1>Horizon 5 Cars</h1>
  <div style="flex: 1"></div>
  <a
    href="https://www.youtube.com/watch?v=p-q6Uu50LXQ"
    aria-label="horizon 5 cars youtube video"
    target="_blank"
    mat-icon-button
    matTooltip="Horizon 5 Cars YouTube Video"
  >
    <img
      ngSrc="../../../assets/images/youtube_logo.png"
      width="40"
      height="40"
      alt="YouTube logo"
    />
  </a>
  <a
    href="https://fm2023.wikylyu.xyz"
    aria-label="fm2023 cars official website"
    target="_blank"
    mat-icon-button
    matTooltip="APP for Forza Motorsport 2023"
  >
    <img
      ngSrc="https://fm2023.wikylyu.xyz/assets/images/logo.png"
      width="40"
      height="40"
      style="border-radius: 2px"
      alt="FM2023 Cars logo"
    />
  </a>

  <a
    routerLink="/about"
    mat-button
    aria-label="go to about page"
    matTooltip="About Horizon 5 Cars"
    >About</a
  >
</mat-toolbar>

<div class="container">
  <h1>Horizon 5 Cars</h1>
  <img
    ngSrc="../../../assets/images/logo.svg"
    width="108"
    height="108"
    priority
    alt="Horizon 5 Cars logo"
  />
  <p>
    A mobile app for game
    <a
      aria-label="go to forza horizon 5 official website"
      href="https://forza.net/horizon"
      target="_blank"
      >Forza Horizon 5</a
    >
  </p>
  <mat-list>
    <mat-list-item>
      <span
        >Contains all
        <a routerLink="/cars">Forza Horizon 5 cars({{ dbinfo?.car_count }})</a
        >.</span
      >
    </mat-list-item>
    <mat-list-item>
      <span>Mark to track collection progress.</span>
    </mat-list-item>
    <mat-list-item>
      <span>Easy filter and sort.</span>
    </mat-list-item>
    <mat-list-item>
      <span>Import/Export car data in CSV format.</span>
    </mat-list-item>
    <mat-list-item>
      <span
        >Detailed information about cars, including engine, layout, weight and
        description.</span
      >
    </mat-list-item>
  </mat-list>

  <app-random-manufacturer-swiper></app-random-manufacturer-swiper>

  <app-random-car-swiper></app-random-car-swiper>

  <span class="check">CHECK MORE CARS IN THE APP</span>

  <div class="download">
    <ngx-google-play-badge
      url="https://play.google.com/store/apps/details?id=xyz.wikylyu.fh5"
    ></ngx-google-play-badge>
    <ngx-app-store-badge
      url="https://apps.apple.com/app/id6467990381"
    ></ngx-app-store-badge>

    <ngx-android-apk-badge
      url="https://apkpure.com/cars-forza-horizon-5/xyz.wikylyu.fh5"
    ></ngx-android-apk-badge>
  </div>
</div>
