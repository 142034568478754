<mat-toolbar color="primary" class="mat-elevation-z4">
  <button mat-icon-button routerLink=".." aria-label="back">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <h1>Froza Horizon 5 Full Car List</h1>
  <div style="flex: 1"></div>
  <button mat-icon-button (click)="jump()" aria-label="jump to manufacturer">
    <mat-icon>skip_next</mat-icon>
  </button>
</mat-toolbar>
@if (!loading) {
<nav mat-tab-nav-bar [tabPanel]="tabPanel">
  @for (m of manufacturers; track $index) {
  <a
    mat-tab-link
    [routerLink]="['/cars', m.name]"
    routerLinkActive="active"
    #rla="routerLinkActive"
    [active]="rla.isActive"
  >
    <img
      ngSrc="../../../assets/images/manufacturer/{{ m | manufacturerImage }}"
      width="36"
      height="36"
      alt="{{ m.name }} logo"
    />
    {{ m.name }}
  </a>
  }
</nav>
<mat-tab-nav-panel #tabPanel>
  <router-outlet></router-outlet>
</mat-tab-nav-panel>
}@else {
<mat-spinner [diameter]="60"></mat-spinner>
}

<button
  class="fab"
  mat-fab
  color="primary"
  aria-label="download Horizon 5 Cars"
  (click)="download()"
>
  <mat-icon>cloud_download</mat-icon>
</button>
