import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  constructor(private _snackBar: MatSnackBar) {}

  info(message: string, action: string | undefined = undefined) {
    return this._snackBar.open(message, action, { duration: 5000 });
  }

  error(message: string, action: string | undefined = undefined) {
    return this._snackBar.open(message, action, { duration: 5000 });
  }
}
