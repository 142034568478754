import { animate, style, transition, trigger } from '@angular/animations';
import { DecimalPipe, NgStyle } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { colorRange } from '@heyeso/color-range';
export const rangedColors = [
  [255, 235, 59],
  [33, 150, 243],
];

const colorsMap = colorRange(rangedColors, [0, 10]);
@Component({
  selector: 'app-car-stat-item',
  standalone: true,
  imports: [MatIconModule, DecimalPipe, NgStyle],
  templateUrl: './car-stat-item.component.html',
  styleUrl: './car-stat-item.component.scss',
  animations: [
    trigger('bar', [
      transition(':enter', [
        style({ width: '0px', 'background-color': '#FFEB3B' }),
        animate('0.5s ease-in-out', style({})),
      ]),
    ]),
  ],
})
export class CarStatItemComponent {
  @Input() icon: string = '';
  @Input() title: string = '';
  @Input() value: number = 0;

  get color() {
    return colorsMap.getColor(this.value).toHex;
  }

  get width() {
    return (this.value / 10.0) * 100 + '%';
  }
}
