<!-- <mat-card matRipple> -->
<h3 matTooltip="{{ name }} {{ data.year }}">
  <span class="name">{{ name }}</span
  ><span class="year">{{ data.year }}</span>
</h3>
<h4>{{ data.manufacturer }}</h4>
<div class="img">
  <img
    ngSrc="../../../assets/images/cars/{{ data | carImage }}"
    fill
    alt="{{ name }} {{ data.year }}"
  />
  <div class="detail">
    <img
      ngSrc="../../../assets/images/drivetrain/{{
        data.drivetrain | drivetrainImage
      }}"
      width="65.25"
      height="34.5"
      alt="{{ name }} {{ data.year }} drivetrain"
    />
    <app-car-pi-label [pi]="data.pi" [klass]="data.pi_class"></app-car-pi-label>
  </div>
</div>
<app-car-rarity-banner [rarity]="data.rarity"></app-car-rarity-banner>
<!-- </mat-card> -->
