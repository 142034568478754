import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CarApiService } from '../../services/car-api.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CarItemComponent } from '../../components/car-item/car-item.component';
import { Title } from '@angular/platform-browser';
import { MatRipple } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { CarDialogComponent } from '../../components/car-dialog/car-dialog.component';

@Component({
  selector: 'app-car-tab-page',
  standalone: true,
  imports: [MatProgressSpinnerModule, CarItemComponent, MatRipple],
  templateUrl: './car-tab-page.component.html',
  styleUrl: './car-tab-page.component.scss',
})
export class CarTabPageComponent {
  constructor(
    private route: ActivatedRoute,
    private api: CarApiService,
    private title: Title,
    private dialog: MatDialog
  ) {
    this.route.params.subscribe((r) => {
      const name = r['name'] || '';
      this.title.setTitle(`Forza Horizon 5 ${name} Car List - Horizon 5 Cars`);
      this.findCars(name);
    });
  }

  cars: any[] = [];
  loading = false;
  async findCars(manufacturer: string) {
    try {
      this.loading = true;
      const r = await this.api.findCars('', manufacturer);
      this.cars = r || [];
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }

  showCarDialog(data: any) {
    this.dialog.open(CarDialogComponent, {
      data: data,
      autoFocus: false,
      width: '480px',
    });
  }
}
