<ngx-swiper [config]="config" height="250px">
  @for (car of cars; track $index) {
  <div class="slide" *ngx-swiper-slide>
    <img
      ngSrc="../../../assets/images/cars/{{ car | carImage }}"
      fill
      alt="{{ car.year }} {{ car.name }}"
    />
  </div>
  }
</ngx-swiper>
