<mat-toolbar color="primary" class="mat-elevation-z4">
  <button mat-icon-button routerLink=".." aria-label="back">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <span class="title">About</span>
</mat-toolbar>

<div class="container">
  <img
    ngSrc="../../../assets/images/129Magikarp.png"
    class="author-avatar"
    alt="developer avatar"
    width="140"
    height="140"
  />

  <p><b>Horizon 5 Cars</b> is created by Wiky Lyu.</p>
  <p>
    Email:
    <a href="mailto:wiky.lyu.dev@gmail.com?subject=Horizon 5 Cars Support"
      >wiky.lyu.dev&#64;gmail.com</a
    >
  </p>
</div>
