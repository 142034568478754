import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'drivetrainImage',
  standalone: true,
})
export class DrivetrainImagePipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): string {
    return value.toLowerCase() + '.webp';
  }
}
