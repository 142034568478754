import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class CarApiService {
  constructor(private http: HttpService) {}

  getDBInfo() {
    const url = this.http.buildurl('/dbinfo');
    return this.http.fget(url);
  }

  findCars(query: string = '', manufacturer: string = '') {
    const url = this.http.buildurl('/cars', { query, manufacturer });
    return this.http.fget(url);
  }

  findManufacturers() {
    const url = this.http.buildurl('/manufacturers');
    return this.http.fget(url);
  }

  findRandomCars(num: number = 25) {
    const url = this.http.buildurl('/random/cars', { num });
    return this.http.fget(url);
  }

  findRandomManufacturers(num: number = 25) {
    const url = this.http.buildurl('/random/manufacturers', { num });
    return this.http.fget(url);
  }
}
