<ngx-swiper [config]="config" height="100px">
  @for (m of manufacturers; track $index) {
  <div class="slide" *ngx-swiper-slide>
    <img
      ngSrc="../../../assets/images/manufacturer/{{ m | manufacturerImage }}"
      fill
      alt="{{ m.name }}"
    />
  </div>
  }
</ngx-swiper>
