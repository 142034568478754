import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import {
  AndroidApkBadgeComponent,
  AppStoreBadgeComponent,
  GooglePlayBadgeComponent,
} from '@wikylyu/ngx-app-store-badge';

@Component({
  selector: 'app-horizon5-download-dialog',
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    GooglePlayBadgeComponent,
    AppStoreBadgeComponent,
    AndroidApkBadgeComponent,
  ],
  templateUrl: './horizon5-download-dialog.component.html',
  styleUrl: './horizon5-download-dialog.component.scss',
})
export class Horizon5DownloadDialogComponent {
  constructor() {}
}
