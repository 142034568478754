import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'manufacturerImage',
  standalone: true,
})
export class ManufacturerImagePipe implements PipeTransform {
  transform(m: any, ...args: unknown[]): String {
    var name = m.name
      .replaceAll(' ', '_')
      .replaceAll('-', '_')
      .replaceAll('&', 'and')
      .replaceAll('/', '_')
      .toLowerCase();
    return `${name}.png`;
  }
}
