@if (loading) {
<mat-spinner [diameter]="60"></mat-spinner>
}@else {
<div class="list">
  @for (car of cars; track $index) {
  <app-car-item
    [data]="car"
    matRipple
    (click)="showCarDialog(car)"
  ></app-car-item>
  }
</div>
}
