<div mat-dialog-title>Download <b>Horizon 5 Cars</b></div>

<div mat-dialog-content class="content">
  <ngx-google-play-badge
    url="https://play.google.com/store/apps/details?id=xyz.wikylyu.fh5"
  ></ngx-google-play-badge>
  <ngx-app-store-badge
    url="https://apps.apple.com/app/id6467990381"
  ></ngx-app-store-badge>

  <ngx-android-apk-badge
    url="https://apkpure.com/cars-forza-horizon-5/xyz.wikylyu.fh5"
  ></ngx-android-apk-badge>
</div>

<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Close</button>
</div>
