<div
  mat-dialog-title
  class="title"
  matTooltip="{{ data?.year }} {{ data?.name }}"
>
  {{ data?.year }} {{ data?.name }}
</div>

<div mat-dialog-content class="content">
  <app-car-item [data]="data"></app-car-item>
  <app-divider>
    <h3>Statstics</h3>
  </app-divider>
  <div class="stats">
    <app-car-stat-item
      icon="speed"
      title="SPEED"
      [value]="data.speed"
    ></app-car-stat-item>
    <app-car-stat-item
      icon="stadia_controller"
      title="HANDLING"
      [value]="data.handling"
    ></app-car-stat-item>
    <app-car-stat-item
      icon="acute"
      title="ACCELERATION"
      [value]="data.acceleration"
    ></app-car-stat-item>
    <app-car-stat-item
      icon="rocket_launch"
      title="LAUNCH"
      [value]="data.launch"
    ></app-car-stat-item>
    <app-car-stat-item
      icon="front_hand"
      title="BRAKING"
      [value]="data.braking"
    ></app-car-stat-item>
    <app-car-stat-item
      icon="flight_takeoff"
      title="OFFROAD"
      [value]="data.offroad"
    ></app-car-stat-item>
  </div>
  <app-divider>
    <h3>Engine</h3>
  </app-divider>
  <div class="lines">{{ data?.engine }}</div>
  <app-divider>
    <h3>Layout</h3>
  </app-divider>
  <div class="lines">{{ data?.layout }}</div>
  <app-divider>
    <h3>Weight</h3>
  </app-divider>
  <div class="lines">
    {{ data?.weight }}
  </div>
  <app-divider>
    <h3>Description</h3>
  </app-divider>
  <div>{{ data?.description }}</div>
</div>

<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Close</button>
</div>
